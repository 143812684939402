import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { PrivateRoute } from "./PrivateRoute";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Side from "./components/sideBar"
import Login from './components/login';
import Grade from './components/grade';
import Essays from './components/essays';
import SettingPage from './components/settings/settingPage';
import EssayFeedBack from './components/essayFeedback';
import Mystudent from './components/Mystudent';
import AddStudent from './components/Mystudent/addStudent';
import ViewStudentProfile from './components/Mystudent/viewStudentProfile';
import StudentLoginPage from './components/login/studentLoginPage';
import AdminLoginPage from './components/adminPages/login';
import AdminStudent from './components/adminPages/student';
import AdminTeacher from './components/adminPages/teacher';
import AddTeacher from './components/adminPages/teacher/addTeacher';
import ViewTeacherProfile from './components/adminPages/teacher/viewTeacherProfile';
import ViewStudentInfo from './components/adminPages/student/viewStudentInfo';
import ExistingTemplate from './components/Templates/index';
import ViewEditTemplate from './components/Templates/viewEditTemplate';

function App() {
  return (
    <>
      <Router>
        <HelmetProvider>
          <Helmet titleTemplate="%s | " defaultTitle="Essay-grader"></Helmet>
        </HelmetProvider>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/studentLogin" element={<StudentLoginPage />} />
          <Route path="/adminLogin" element={<AdminLoginPage />} />
        </Routes>
        <Routes>
          <Route element={<PrivateRoute />} >
            <Route path="/grade" element={<Grade />} />
            <Route path="/essay/feedback" element={<EssayFeedBack />} />
            <Route path="/regen_grade/:id" element={<EssayFeedBack />} />
            <Route element={<Side />}>
              <Route path="/essays" element={<Essays />} />
              <Route path="/setting" element={<SettingPage />} />
              <Route path="/students" element={<Mystudent />} />
              <Route path="/students/:id" element={<ViewStudentProfile />} />
              <Route path="/admin/students" element={<AdminStudent />} />
              <Route path="/admin/teachers" element={<AdminTeacher />} />
              <Route path="/teacher/:id" element={<ViewTeacherProfile />} />
              <Route path="/student-info/:id" element={<ViewStudentInfo />} />
              <Route path="/templates" element={<ExistingTemplate />} />
              <Route path="/editTemplate" element={<ViewEditTemplate />} />
            </Route>
            <Route path="/add-student" element={<AddStudent />} />
            <Route path="/add-teacher" element={<AddTeacher />} />
          </Route>
        </Routes>
      </Router>
      <ToastContainer
        autoClose={3000}
        progressClassName="toastProgress"
        bodyClassName="toastBody"
      />
    </>
  );
}

export default App;
