import React, { useEffect, useState } from 'react'
import axios from 'axios';
import "./style.css"
import { Button, Tab, Box, FormGroup, FormControlLabel, Checkbox, CircularProgress, TextField, Modal, Select, MenuItem } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import PropTypes from 'prop-types';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import { useRef } from 'react';
import moment from 'moment/moment';
import { BASE_URL } from '../../api/client';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 0 }}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


function EssayFeedBack() {
    const navigate = useNavigate()
    const { id } = useParams();
    const state = useLocation();
    console.log("id-param", id)
    const [tabValue, setTabValue] = React.useState(0);
    const [quePageTab, setQuePageTab] = useState(0)
    const [, setOverAllTab] = useState(0)
    const [lowPriorityState, setLowPriorityState] = useState(true);
    const [mediumPriorityState, setMediumPriorityState] = useState(true);
    const [topPriorityState, setTopPriorityState] = useState(true);
    const [enableHighlightingState, setEnableHighlightingState] = useState(false);
    const [feedBackState, setFeedBackState] = useState(id ? state?.state?.data : state?.state?.data?.data_json)
    const [essayID, setEssayID] = useState(id ? id : state?.state?.data?.id)
    const [feedBackQuestionModal, setFeedBackQuestionModal] = useState(false)
    const [feedBackQuestionImage, setFeedBackQuestionImage] = useState("")
    const inputRef = useRef(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [cardErrorMessages, setCardErrorMessages] = useState([]);
    const roleType = JSON.parse(localStorage.getItem('userDetails'));
    const [loading, setLoading] = useState(false)
    const [closeIconVisible, setCloseIconVisible] = useState();
    const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState(false);
    const [openAddCardDialog, setOpenAddCardDialog] = useState(false);
    const [deleteIDS, setDeleteIDS] = useState({
        quesIndex: "",
        quespageIndex: "",
        cardIndex: ""
    });
    const [priorityScoreStatus, setPriorityScoreStatus] = useState("");
    const [newCardContent, setNewCardContent] = useState("");
    const errors = {}
    const [formErrors, setFormErrors] = useState({})
    const [numberOfImageUrls, setNumberOfImageUrls] = useState(0);

    useEffect(() => {
        const currentDate = moment(new Date()).toISOString();
        if (currentDate > roleType?.expiry) {
            localStorage.removeItem("userDetails");
            toast.error("session expired");
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        }
        function countImageUrls(obj) {
            let count = 0;
            function traverse(obj) {
                for (let key in obj) {
                    if (typeof obj[key] === 'object' && obj[key] !== null) {
                        traverse(obj[key]);
                    } else if (key === 'image_url') {
                        count++;
                    }
                }
            }
            traverse(obj);
            return count;
        }
        let imageUrlsCount = countImageUrls(feedBackState)
        setNumberOfImageUrls(imageUrlsCount);
    }, []);

    useEffect(() => {
        mediumPriorityFunction();
        lowPriorityFunction();
        topPriorityFunction();
        handleHighlights();
    }, [mediumPriorityState, enableHighlightingState, lowPriorityState, topPriorityState, tabValue])

    const handleTabChange = (event, newValue) => {
        if (!errorMessage && !cardErrorMessages.some(message => message)) {
            setTabValue(newValue);
            setQuePageTab(0)
            setOverAllTab(newValue)
        }
    };

    const handleNavigate = () => {
        if (!loading) {
            if (id) {
                navigate("/essays");
            }
            else {
                navigate("/grade");
            }
        }
    }

    const handleGoToEssay = () => {
        if (!loading) {
            navigate("/essays")
        }
    }

    // Function to update overall feedback
    function updateOverallFeedback(questionIndex, newText) {
        if (newText !== undefined) {
            if (newText.trim() === '') {
                setErrorMessage('Please fill the overall feedback');
                toast.error("The overall feedback will be not empty")
                inputRef?.current?.focus();
            }
            else {
                setErrorMessage("")
                let arrayOfStrings = newText.split('\n');
                const updatedFeedbackData = { ...feedBackState };
                updatedFeedbackData.question_responses[questionIndex].overall_feedback = [...arrayOfStrings];
                setFeedBackState(updatedFeedbackData);
            }
        }
    }

    // Function to update feedback cards
    function updateFeedback(questionIndex, pageIndex, pageDataIndex, newText) {
        const updatedErrorMessages = [...cardErrorMessages];
        if (newText !== undefined) {
            if (newText.trim() === '') {
                console.error('Error: Text cannot be empty.');
                updatedErrorMessages[pageDataIndex] = ("Please fill the cards")
                setCardErrorMessages(updatedErrorMessages)
            }
            else {
                updatedErrorMessages[pageDataIndex] = ""
                setCardErrorMessages(updatedErrorMessages)
                const updatedFeedBackState = [...feedBackState?.question_responses];
                updatedFeedBackState[questionIndex].pages[pageIndex].feedback[pageDataIndex].feedback = newText;
                setFeedBackState({ ...feedBackState, question_responses: updatedFeedBackState });
            }
        }
    }

    function getPriorityBannerClass(priorityScore) {
        if (priorityScore === 3) {
            return 'high-priority-banner';
        } else if (priorityScore === 2) {
            return 'medium-priority-banner';
        } else {
            return 'low-priority-banner';
        }
    }

    const openModal = (src) => {
        setFeedBackQuestionModal(true)
        setFeedBackQuestionImage(src)
    }

    function handleCloseModal() {
        setFeedBackQuestionModal(false)
        setFeedBackQuestionImage("")
    }


    const showPage = (questionIndex, selectedPageIndex) => {
        if (!cardErrorMessages.some(message => message)) {
            setOverAllTab(questionIndex)
            setQuePageTab(selectedPageIndex)
        }
        else {
            toast.error("Please fill the cards")
        }
    }

    const handleSubmitFeedbackChanges = () => {
        let payLoad = {
            changejson: feedBackState,
            essayid: essayID,
        }
        console.log(cardErrorMessages.length === 0 ? "okay" : "notokay", "cardErrorMessages", cardErrorMessages, cardErrorMessages.some(message => message))
        let allCardsNotEmpty = true;
        feedBackState.question_responses.forEach((questionResponse, questionIndex) => {
            questionResponse.pages.forEach((page, pageIndex) => {
                page.feedback.forEach((card, cardIndex) => {
                    if (card.feedback.trim() === "") {
                        allCardsNotEmpty = false;
                    }
                });
            });
        });

        if (cardErrorMessages.some(message => message)) {
            toast.error("Please fill the cards")
        }
        else if (!allCardsNotEmpty) {
            toast.error("Please fill the cards")
        }
        if (!errorMessage && !cardErrorMessages.some(message => message) && allCardsNotEmpty) {
            setLoading(true);
            axios
                .post(`${BASE_URL}/modifyessay/`, payLoad)
                .then((res) => {
                    const respo = res?.status === 200;
                    if (respo) {
                        setLoading(false);
                        setFeedBackState(res?.data?.newcontent)
                        toast.success("Feedback saved successfully")
                    }
                    else {
                        toast.error("Feedback save failed")
                        setLoading(false);
                    }
                })
                .catch((err) => {
                    setLoading(false);
                    toast.error("Feedback save failed")
                    console.log('error in axios', err);
                });
        }
    }

    function showPriority(attr, flag) {
        let elements = document.getElementsByClassName(flag);
        const elementsArray = Array.from(elements);
        elementsArray.forEach(elem => {
            elem.classList.remove('hidePriorityClass');
            elem.classList.add('showPriorityClass');
        });
    }

    function hidePriority(attr, flag) {
        let elements = document.getElementsByClassName(flag);
        const elementsArray = Array.from(elements);
        elementsArray.forEach(elem => {
            elem.classList.remove('showPriorityClass');
            elem.classList.add('hidePriorityClass');
        });
    }

    const topPriorityFunction = (e) => {
        if (topPriorityState) {
            showPriority('card-banner', 'high-priority-banner');
        }
        else {
            hidePriority('card-banner', 'high-priority-banner');
        }
    }

    function mediumPriorityFunction(e) {
        const flag = 'medium-priority-banner';
        if (mediumPriorityState) {
            showPriority('card-banner', flag);
        }
        else {
            hidePriority('card-banner', flag);
        }
    }

    function lowPriorityFunction(e) {
        if (lowPriorityState) {
            showPriority('card-banner', 'low-priority-banner');
        }
        else {
            hidePriority('card-banner', 'low-priority-banner');
        }
    }

    function removeEventListenersFromFeedbackCards() {
        const feedbackCards = document.querySelectorAll('.feedback-card');
        feedbackCards.forEach(card => {
            card.removeEventListener('mouseover', handleMouseOver);
        });
    }

    function addEventListenersToFeedbackCards() {
        const feedbackCards = document.querySelectorAll('.feedback-card');
        feedbackCards.forEach(card => {
            card.addEventListener('mouseover', handleMouseOver);
        });
        feedbackCards.forEach(card => {
            card.addEventListener('mouseout', handleMouseOut);
        });
    }

    function handleMouseOver(event) {
        let card;
        if (event.target.getAttribute('class') === 'feedbackprioritycontent') {
            card = event.target.parentElement
        } else {
            card = event.target
        }

        // Check if the toggle button is checked and the card is present
        let dummy = document.getElementById("highlightToggle")
        if (dummy?.checked) {
            const index = Number(card.getAttribute('data-index'));
            const pageIndex = Number(card.getAttribute('data-pageIndex'));
            const feedbackBox = JSON.parse(card.getAttribute('data-feedbackBox'));

            if (feedbackBox !== null) {
                highlightEllipse(index, pageIndex, feedbackBox);
            }
        }
    }

    function handleMouseOut(event) {
        const card = event.target;
        const index = Number(card.getAttribute('data-index'));
        const pageIndex = Number(card.getAttribute('data-pageIndex'));
        clearEllipse(index, pageIndex)
    }

    function clearEllipse(questionIndex, pageIndex) {
        let overlayIds = 'overlay-' + questionIndex + '-' + pageIndex;
        let overlays = document.getElementById(overlayIds);
        if (overlays !== null) {
            overlays.style.display = 'none';
        }
    }

    const handleHighlights = (e) => {
        if (enableHighlightingState) {
            addEventListenersToFeedbackCards();
        }
        else {
            removeEventListenersFromFeedbackCards();
        }
    }

    const highlightEllipse = (questionIndex, pageIndex, boxCoords) => {
        let overlayId = 'overlay-' + questionIndex + '-' + pageIndex;
        let overlay = document.getElementById(overlayId);
        let boxes = [];
        // Calculate the center
        let xCenter = (boxCoords[0] + boxCoords[2]) / 2;
        let yCenter = (boxCoords[1] + boxCoords[3]) / 2;
        // Calculate radii
        overlay.style.setProperty('--overlay-y', `${yCenter}px`);
        overlay.style.setProperty('--overlay-x', `${xCenter}px`);
        for (let i = 0; i < boxCoords.length; i += 4) {
            let x1 = boxCoords[i];
            let y1 = boxCoords[i + 1];
            let x2 = boxCoords[i + 2];
            let y2 = boxCoords[i + 3];
            // Calculate the center
            let xCenter = (x1 + x2) / 2;
            let yCenter = (y1 + y2) / 2;
            // Calculate radius (assuming yRadius is the desired radius)
            let yRadius = Math.abs(y2 - y1) / 2;
            // Store the center and radius
            boxes.push({ xCenter, yCenter, yRadius });
        }

        boxes.sort((a, b) => a.yCenter - b.yCenter);
        let allBoxesAreZero = boxes.every(box => box.yCenter === 0 && box.yRadius === 0);
        let gradientStops = [];
        if (allBoxesAreZero) {
            overlay.style.background = 'rgba(0, 0, 0, 0.0)';
        }
        else {
            gradientStops.push(`rgba(0, 0, 0, 0.6) 0%`);
            boxes.forEach(box => {
                gradientStops.push(`rgba(0, 0, 0, 0.5) ${box.yCenter - box.yRadius}px`);
                gradientStops.push(`rgba(0, 0, 0, 0.4) ${box.yCenter - 1 * box.yRadius}px`);
                gradientStops.push(`rgba(0, 0, 0, 0.0) ${box.yCenter - 0.9 * box.yRadius}px`);
                gradientStops.push(`rgba(0, 0, 0, 0.0) ${box.yCenter}px`);
                gradientStops.push(`rgba(0, 0, 0, 0.0) ${box.yCenter + 0.9 * box.yRadius}px`);
                gradientStops.push(`rgba(0, 0, 0, 0.4) ${box.yCenter + 1 * box.yRadius}px`);
                gradientStops.push(`rgba(0, 0, 0, 0.5) ${box.yCenter + box.yRadius}px`);
            });

            // Final gradient stop at the end
            gradientStops.push(`rgba(0, 0, 0, 0.6) 100%`);

            // Set the background gradient
            overlay.style.background = `
        linear-gradient(to right, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.0) 25%, rgba(0, 0, 0, 0.0) 75%, rgba(0, 0, 0, 0.6) 100%),
        linear-gradient(to bottom, ${gradientStops.join(', ')})
    `;
        }
        // Show the overlay
        overlay.style.display = 'block';
    }

    function createFeedbackHTML(data) {
        var html = "";
        for (var q = 0; q < data.question_responses.length; q++) {
            var questionResponse = data.question_responses[q];
            html += "<div class='feedback-question-section'>";
            html += "<p class='question-elem'>" + questionResponse.question + "</p>";
            html += "<div class='feedback-section'>";
            html += "<h2>Overall Feedback</h2>";
            html += "<ul>";
            for (var i = 0; i < questionResponse.overall_feedback.length; i++) {
                html += "<li>" + questionResponse.overall_feedback[i] + "</li>";
            }
            html += "</ul>";
            html += "</div>";
            html += "<div class='feedback-section'>";
            html += "<h2>Individual Feedback Pages</h2>";
            for (var j = 0; j < questionResponse.pages.length; j++) {
                var page = questionResponse.pages[j];
                html += "<div class='feedback-item'>";
                html += `<div class='highlighter' id='overlay-${q}-${j}'></div>`;
                html += "<img src='" + page.image_url + "' alt='Page " + (j + 1) + "' class='feedback-image'>";
                html += "<ol start='1'>";
                for (var k = 0; k < page.feedback.length; k++) {
                    var feedbackItem = page.feedback[k];
                    var feedbackText = feedbackItem.feedback;
                    html += "<li>" + feedbackText + "</li>";
                }
                html += "</ol>";
                html += "</div>";
            }
            html += "</div>";
        }

        return html;
    }

    const initiatePdfDownload = async () => {
        localStorage.setItem('pdfDownloadObject', JSON.stringify(feedBackState));
        const newTab = window.open('/new-tab', '_blank');
        if (newTab) {
            newTab.onload = async () => {
                const styleElement = newTab.document.createElement('style');
                styleElement.textContent = `
            body {
                font-family: Arial, sans-serif;
            }
            .feedback-section {
                margin-bottom: 20px;
                padding: 0px 10px;
            }
            .feedback-image {
                max-width: 100%;
                height: auto;
                width: 500px;
            }
            .feedback-item {
                margin-bottom: 10px;
            }
            .feedback-question-section p {
                position: relative;
                font-size: 22px;
                font-weight: 600;
                padding: 0px 10px;
            }
            .feedback-number {
                color: red;
            }
            .highlighter {
                position: absolute;
                border: 2px solid red; */
            }
            .innerSpanCustomCss {
                position: relative;
                padding: 0px 2px;
                color: darkred;
                font-size: 20px;
            }
            .question-elem{
                page-break-before: always;
            }
        `;
                newTab.document.head.appendChild(styleElement);
                newTab.document.title = `Essay_${id}_${moment(new Date()).format("DD-MM-YYYY")}_${moment(new Date()).format("hh-mm")}.pdf`;
                let data = createFeedbackHTML(feedBackState)
                newTab.document.body.innerHTML = data;
                const scriptElement = newTab.document.createElement('script');
                scriptElement.textContent = `
                function areElementsOverlapping(parentNode, element2) {
                    if (parentNode !== null && element2 !== null) {
                        const children = parentNode.children;
                        for (let i = 0; i < children.length; i++) {
                            const element1 = children[i];
                            const rect1 = {
                                left: parseFloat(element1.style.left),
                                top: parseFloat(element1.style.top),
                                width: parseFloat(element1.style.width),
                                height: parseFloat(element1.style.height),
                            };
        
                            const rect2 = {
                                left: parseFloat(element2.style.left),
                                top: parseFloat(element2.style.top),
                                width: parseFloat(element2.style.width),
                                height: parseFloat(element2.style.height),
                            };
        
                            if (
                                rect1.left === rect2.left &&
                                rect1.top === rect2.top &&
                                rect1.height === rect2.height &&
                                rect1.width === rect2.width
                            ) {
                                return { element: element1, isMatched: true };
                            }
                        }
                    }
                    return { element: null, isMatched: false };
                }
                function highlightRectangles(questionIndex, pageIndex, boxCoords, pageCounter, name) {
                    let overlayId = 'overlay-' + questionIndex + '-' + pageIndex;
                    let overlay = document.getElementById(overlayId);
                    let boxes = [];
                    for (let i = 0; i < boxCoords.length; i += 4) {
                        let x1 = boxCoords[i];
                        let y1 = boxCoords[i + 1];
                        let x2 = boxCoords[i + 2];
                        let y2 = boxCoords[i + 3];
                        if (x1 > 0 || y1 > 0 || x2 > 0 || y2 > 0) {
                            boxes.push({ x1, y1, x2, y2 });
                        }
                    }
                    if (boxes.length > 0) {
                        overlay.style.display = 'block';
                        boxes.forEach((box, index) => {
                            let rectangle = document.createElement('div');
                            rectangle.style.position = 'absolute';
                            rectangle.style.border = '2px solid red';
                            rectangle.style.left = '50px';
                            rectangle.style.top = box.y1 + 'px';
                            rectangle.style.width = '425px';
                            rectangle.style.height = (box.y2 - box.y1) * 0.911 + 'px';
                            rectangle.style.zIndex = '999';
                            rectangle.style.textAlign = 'right';
                            let innerSpan = document.createElement('span');
                            innerSpan.className = 'innerSpanCustomCss';
                            innerSpan.innerText = name;
                            rectangle.appendChild(innerSpan);
                            let tempelem = areElementsOverlapping(overlay, rectangle);
                            if (tempelem.isMatched) {
                                console.log('Overlap detected!');
                                tempelem.element?.insertAdjacentHTML('beforeend', rectangle.innerHTML);
                            } else {
                                console.log('No overlap detected.');
                                overlay.appendChild(rectangle);
                            }
                        });
                    }
                }
                    let pageCounter = 0;
                    for (let q = 0; q < ${JSON.stringify(feedBackState)}.question_responses.length; q++) {
                        for (let p = 0; p < ${JSON.stringify(feedBackState)}.question_responses[q].pages.length; p++) {
                            let page = ${JSON.stringify(feedBackState)}.question_responses[q].pages[p];
                            let imageCoords = page.feedback.map((item, index) => ({ url: page.image_url, box: item.box, name: \`\${index + 1}\` }));
                            pageCounter++
                            for (let i = 0; i < imageCoords.length; i++) {
                                highlightRectangles(q, p, imageCoords[i].box, pageCounter, imageCoords[i].name);
                            }
                        }
                    }
                `;
                newTab.document.body.appendChild(scriptElement);
                setTimeout(() => {
                    try {
                        newTab.window.print();
                    } catch (error) {
                        console.error('Error generating PDF:', error);
                    }
                }, (numberOfImageUrls > 15 ? numberOfImageUrls * 100 : 1500));
            };
        }
    }

    const deleteFeedbackCard = () => {
        const updatedFeedback = [...feedBackState.question_responses[deleteIDS?.quesIndex].pages[deleteIDS?.quespageIndex].feedback];
        if (updatedFeedback.length === 1) {
            toast.error("Cannot delete the last feedback card");
            setOpenDeleteConfirmDialog(false);
        }
        else {
            updatedFeedback.splice(deleteIDS?.cardIndex, 1);
            const updatedState = [...feedBackState.question_responses];
            updatedState[deleteIDS?.quesIndex].pages[deleteIDS?.quespageIndex].feedback = updatedFeedback;
            setFeedBackState({ ...feedBackState, question_responses: updatedState });
            setOpenDeleteConfirmDialog(false);
            toast.success("Feedback point deleted successfully")
        }
    };

    const hanldeDeleteConfirmOpen = (questionIndex, pageIndex, pageDataIndex) => {
        setOpenDeleteConfirmDialog(true)
        setDeleteIDS({
            quesIndex: questionIndex,
            quespageIndex: pageIndex,
            cardIndex: pageDataIndex
        })
    }
    const handleDeleteConfirmClose = () => {
        setOpenDeleteConfirmDialog(false);
    };

    const handleAddCardOpen = () => {
        setOpenAddCardDialog(true)
    };

    const handleAddCardSave = () => {
        console.log("tabValue", tabValue, "quePageTab", quePageTab)
        if (newCardContent.trim() === "") {
            errors.newCardContent = "* Please fill the field"
        }
        if (priorityScoreStatus === "") {
            errors.newPriorityScore = "* Please select an priprity"
        }
        setFormErrors(errors)
        if (Object.keys(errors).length === 0) {
            const newFeedbackCard = {
                box: [0, 0, 0, 0],
                feedback: newCardContent,
                priority_score: priorityScoreStatus,
                isCustomFeedBack: true,
            };
            const updatedState = [...feedBackState.question_responses];
            updatedState[tabValue].pages[quePageTab].feedback.push(newFeedbackCard);
            setFeedBackState({ ...feedBackState, question_responses: updatedState });
            handleAddCardCancel()
        }
    }

    const handleAddCardCancel = () => {
        setOpenAddCardDialog(false)
        setNewCardContent("")
        setPriorityScoreStatus()
        setFormErrors({})
    }

    return (
        <div className='essay-feedback-parent-warps'>
            <div id="myModalOne" style={{ display: feedBackQuestionModal ? "block" : "none" }} className="modal">
                <div className="modal-content">
                    <span onClick={handleCloseModal} onKeyDown={(event) => {
                        if (event.key === "Escape" || event.key === "Esc" || event.keyCode === 27) {
                            handleCloseModal();
                        }
                    }} className="close">&times;</span>
                    <img id="modalImage" src={feedBackQuestionImage} alt="queimage" style={{ width: "100%" }} />
                </div>
            </div>
            <div className="feedback-header-section">
                <div className='header-first-section'>
                    <img src="https://cdn4.iconfinder.com/data/icons/wirecons-free-vector-icons/32/back-alt-512.png" alt="back icon"
                        width="32px" height="32px" onClick={handleNavigate} style={{ cursor: loading ? "not-allowed" : "pointer" }} />
                    <p className="commonHeaderBoldStyles">Dashboard </p>
                    <strong ><KeyboardArrowRightIcon /></strong>
                    <p className="headerMainTitle">Essay Feedback</p>
                </div>
                <div className="navSection">
                    <div className='feedback-page-GoToEssay' style={{ cursor: loading ? "not-allowed" : "pointer" }} onClick={handleGoToEssay}>Go to Essays</div>
                    <div style={{ position: "relative", margin: "0px 14px" }}>
                        <Button type="button" onClick={initiatePdfDownload} className="view-download-btn">View download page</Button>
                    </div>
                </div>
            </div>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="question-tabs">
                <Tabs value={tabValue} onChange={handleTabChange} aria-label="lab API tabs example" TabIndicatorProps={{ style: { display: "none" } }}>
                    {feedBackState?.question_responses?.map((data, index) => (
                        <Tab key={index} label={"Question " + (index + 1)} {...a11yProps(index)} />
                    ))}
                </Tabs>
            </Box>
            {feedBackState?.question_responses?.map((data, questionIndex) => (
                <TabPanel value={tabValue} key={questionIndex} index={questionIndex}>
                    <div className='question-parent-div-warp'>
                        <strong className='question-lables'>Question</strong>
                        {data?.question === "" ? <div className='empty-quesion-div'>N/A</div> : <div className='question-values' >{data?.question}</div>}
                    </div>
                    <div className='question-parent-div-warp'>
                        <strong className='question-lables'>Overall Feedback :</strong>
                        {data?.overall_feedback.length === 0 ? <div className='empty-quesion-div'>N/A</div> :
                            <div>
                                <p
                                    className="question-div-value-label"
                                    contentEditable={true}
                                    onBlur={(e) => updateOverallFeedback(questionIndex, e.target.innerText)}
                                    ref={inputRef}
                                    dangerouslySetInnerHTML={{
                                        __html: `<ul>${data.overall_feedback
                                            .map((item) => `<li>${item}</li>`)
                                            .join('')}</ul>`
                                    }}
                                />
                            </div>
                        }
                    </div>

                    {data?.pages?.map((page, pageIndex) => (
                        <div key={pageIndex} style={{ display: pageIndex === quePageTab ? "block" : "none" }} className={`tab-page, tab-${questionIndex}, page`}>
                            <div className='page-content-main-wrapper'>
                                <div className='page-content-image-wrapper'>
                                    <img src={page?.image_url} alt='pages' style={{ width: 500 }} onClick={() => openModal(page?.image_url)} />
                                    <div id={`overlay-${questionIndex}-${pageIndex}`} className='image-overlay'></div>
                                </div>
                                <div className='page-content-feedback-section-wrapper'>
                                    {page?.feedback?.length === 0 ? <div className='cards-empty-div'>N/A</div> : page.feedback.map((pageData, pageDataIndex) => (
                                        <div key={pageDataIndex}
                                            data-index={questionIndex} data-pageindex={pageIndex} data-feedbackbox={JSON.stringify(pageData?.box)} className="feedback-card"
                                            style={{ backgroundColor: pageData?.isCustomFeedBack ? "#14040424" : "#ffffff" }} onMouseEnter={() => setCloseIconVisible(pageDataIndex)} onMouseLeave={() => setCloseIconVisible(null)}>
                                            <div className='cancel-icon-div'
                                                style={{ display: closeIconVisible === pageDataIndex ? 'flex' : 'none', }}
                                            >
                                                <CancelOutlinedIcon onClick={() => hanldeDeleteConfirmOpen(questionIndex, pageIndex, pageDataIndex)} className='cancel-icon-font' />
                                            </div>
                                            <div className="feedbackCardInnerWrapper">
                                                <div className={`card-banner ${getPriorityBannerClass(pageData.priority_score)}`}> </div>
                                            </div>
                                            <p contentEditable={true} onBlur={(e) => updateFeedback(questionIndex, pageIndex, pageDataIndex, e.target.innerText)} className="feedbackprioritycontent">{pageData?.feedback ? pageData?.feedback : ""}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))}
                    <div style={{ display: "flex" }}>
                        <div style={{ width: "40%" }}>
                            <Checkbox id="highlightToggle" checked={enableHighlightingState} style={{ display: "none" }} />
                            <FormGroup>
                                <FormControlLabel control={<Checkbox id="highlightToggle" checked={enableHighlightingState} onChange={(e) => setEnableHighlightingState(e.target.checked)} />} label="Enable Highlighting" />
                                <FormControlLabel control={<Checkbox checked={topPriorityState} onChange={(e) => setTopPriorityState(e.target.checked)} />} label="Highlight Top Priority" />
                                <FormControlLabel control={<Checkbox checked={mediumPriorityState} onChange={(e) => setMediumPriorityState(e.target.checked)} />} label="Highlight Medium Priority" />
                                <FormControlLabel control={<Checkbox checked={lowPriorityState} onChange={(e) => setLowPriorityState(e.target.checked)} />} label="Highlight Low Priority" />
                            </FormGroup>
                        </div>
                        <div className='pagination-container-div-warp'>
                            <div className='pagination-container'>
                                {data?.pages?.map((page, pageIndex) => (
                                    <Button key={pageIndex} className='paginationBtn' onClick={() => showPage(questionIndex, pageIndex)}
                                        style={{
                                            color: quePageTab === pageIndex ? 'white' : 'black',
                                            backgroundColor: quePageTab === pageIndex ? 'black' : 'white',
                                        }}
                                    >{pageIndex + 1}</Button>
                                ))}
                            </div>
                            <div className='add-card-btn-div'>
                                <Button className="saveAllChanges-btn" onClick={handleAddCardOpen} variant="contained">Add new feedback</Button>
                            </div>
                        </div>
                    </div>
                </TabPanel>
            ))
            }
            <div className="btn-main-wrapper" style={{ display: "flex", justifyContent: "center", padding: "10px" }}>
                <Button variant="contained" className="saveAllChanges-btn" onClick={() => handleSubmitFeedbackChanges()}>
                    {loading ? <CircularProgress size={24} sx={{ color: "#fff" }} /> : "Save all changes"}
                </Button>
            </div>
            <Dialog
                open={openDeleteConfirmDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete?
                    </DialogContentText>
                </DialogContent>
                <DialogActions className='agree-disagree-btn-div'>
                    <Button variant="contained" onClick={handleDeleteConfirmClose} className="disagree-btn">Cancel</Button>
                    <Button variant="contained" onClick={deleteFeedbackCard} style={{ textTransform: "capitalize" }} autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
            <Modal
                open={openAddCardDialog}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className='add-card-modal-box'>
                    <div>
                        <div className='add-card-filed-gap'>
                            <Select
                                id="prioritySelect"
                                name="prioritySelect"
                                className="login-text-fields"
                                value={priorityScoreStatus}
                                onChange={(e) => setPriorityScoreStatus(e.target.value)}
                                sx={{
                                    "& .MuiSelect-select .notranslate::after":
                                        "Select a filter"
                                            ? { content: `"Select priority"`, opacity: 1 }
                                            : {},
                                }}
                            >
                                <MenuItem value={3}>Top Priority</MenuItem>
                                <MenuItem value={2}>Medium Priority</MenuItem>
                                <MenuItem value={1}>Low Priority</MenuItem>
                            </Select>
                            <div className="errer-message">{formErrors.newPriorityScore}</div>
                        </div>
                        <div className='add-card-filed-gap'>
                            <TextField
                                multiline
                                className='login-text-fields'
                                fullWidth
                                value={newCardContent}
                                onChange={(e) => setNewCardContent(e.target.value)}
                                rows={4} />
                            <div className="errer-message">{formErrors.newCardContent}</div>
                        </div>
                    </div>
                    <div className='add-card-modal-btn-div'>
                        <div className='add-card-cancel-div'>
                            <Button variant="contained" onClick={handleAddCardCancel} className="disagree-btn">Cancel</Button>
                        </div>
                        <div className='add-card-save-div'>
                            <Button variant="contained" onClick={handleAddCardSave} style={{ textTransform: "capitalize" }} autoFocus>
                                Save
                            </Button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    )
}

export default EssayFeedBack