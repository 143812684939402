import data from './data.json'; // Import sample data
import GradeData from "./grade.json";

export const fetchData = async () => {
  // Simulate an API call by returning a Promise with sample data
  return Promise.resolve(data);
};

export const fetchGradeData = async () => {
  // Simulate an API call by returning a Promise with sample data
  return Promise.resolve(GradeData);
};
