import React, { useState } from 'react';
import axios from 'axios';
import "./style.css"
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useLocation } from "react-router-dom";
import { CircularProgress, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TablePagination, TextField } from '@mui/material';
import { Button } from '@mui/material';
import { BASE_URL } from '../../api/client';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        padding: 12
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function ViewEditTemplate() {
    const state = useLocation();
    const navigate = useNavigate();
    const [viewTemplateData, setViewTemplateData] = useState(JSON.parse(state?.state?.data?.data) || [])
    const [templateName, setTemplateName] = useState(state?.state?.data?.name || "")
    const [handwriting, setHandwriting] = useState(state?.state?.data?.isHandwriting ? 1 : 0)
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [originalRowData, setOriginalRowData] = useState([]);
    const [editEnable, setEditEnable] = useState();
    const [editFlag, setEditFlag] = useState(true);
    const [loading, setLoading] = useState(false)
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleTemplateRowEdit = (e, index) => {
        if (!editFlag) {
        }
        else {
            setEditEnable(index)
            setEditFlag(false);
            setOriginalRowData({ ...viewTemplateData[index] });
        }
    }

    const handleTemplateRowUpdate = (e, index) => {
        const list = [...viewTemplateData];
        const currentRow = list[index];
        const isEmpty = currentRow.question.trim() === '';
        if (isEmpty) {
            toast.error('Please fill all the fields');
        }
        else {
            setEditFlag(true);
            setEditEnable()
        }
    }

    const handleTemplateNewRowAdd = () => {
        if (editFlag) {
            const newRow = {
                question: '',
                max_marks: '',
                max_words: '',
                model_solution: '',
                special_instructions: ''
            };
            const addedTemplateData = [...viewTemplateData];
            const lastIndex = addedTemplateData?.length - 1
            const lastRow = addedTemplateData[lastIndex]
            const isEmpty = lastRow.question.trim() === '';
            if (isEmpty) {
                toast.error('Please fill all the last row fields');
            }
            else {
                addedTemplateData.push(newRow);
                setViewTemplateData(addedTemplateData);
                setEditEnable(addedTemplateData?.length - 1);
                setEditFlag(false);
                setOriginalRowData(newRow)
            }
        }
    };

    const handleTemplateRowDelete = (e, index) => {
        if (editFlag) {
            const deletedRows = [...viewTemplateData];
            if (deletedRows.length === 1) {
                toast.warning("user can't delete all rows")
            }
            else {
                deletedRows.splice(index, 1);
                setViewTemplateData(deletedRows);
            }
        }
    };

    const handleTableRowEdit = (e, index) => {
        let { name, value } = e.target;
        if (name === 'max_marks' || name === 'max_words') {
            value = value.replace(/\D/g, '');
        }
        const list = [...viewTemplateData];
        list[index][name] = value;
        setViewTemplateData(list);
    }

    const handleTemplateRowCancel = (e, index) => {
        const updatedData = [...viewTemplateData];
        updatedData[index] = originalRowData;

        const isEmpty = updatedData[index].question.trim() === '';
        if (isEmpty) {
            toast.error('Please fill all the fields');
        }
        else {
            setViewTemplateData(updatedData);
            setEditEnable();
            setEditFlag(true);
        }
    };

    const handleTemplateUpdateSave = () => {
        const isEmpty = viewTemplateData.some(row => row.question.trim() === '');
        let payload = {
            template_id: state?.state?.data?.id,
            newcontents: {
                data: JSON.stringify(viewTemplateData),
                name: state?.state?.data?.name,
                createdAt: state?.state?.data?.createdAt,
                createdBy: state?.state?.data?.createdBy,
            },
            isHandwriting: handwriting
        }
        if (isEmpty) {
            toast.error('Please fill all fields');
        } else {
            setLoading(true);
            axios
                .post(`${BASE_URL}/modifytemplate/`, payload)
                .then((res) => {
                    const respo = res?.status === 200;
                    if (respo) {
                        setLoading(false);
                        toast.success('Template updated successfully');
                        navigate("/templates");
                    }
                    else {
                        setLoading(false);
                        toast.error("Template updated failed");
                        navigate("/templates");
                    }
                })
                .catch((err) => {
                    setLoading(false);
                    toast.error("Template updated failed");
                    navigate("/templates");
                    console.log('error in axios', err);
                });
        }
    }

    const handleBackToTemplate = () => {
        navigate("/templates")
    }

    return (
        <div style={{ padding: "40px 20px" }}>
            <Grid container spacing={2}>
                <Grid item sx={12} md={6} lg={6}>
                    <div className='studentview-back-btn-parent-div'>
                        <div className='studnetView-back-btn-div'>
                            <Button onClick={handleBackToTemplate} variant="outlined" className='student-back-btn'><ArrowBackIcon /> Back</Button>
                        </div>
                        <div><h5 className='page-name'>Templates name : {templateName}</h5></div>
                    </div>
                </Grid>
                <Grid item sx={12} md={6} lg={6}>
                    <div className='add-row-btn-parent-div'>
                        <Button className="add-row-btn-view-edit" variant="outlined" onClick={handleTemplateNewRowAdd}>Add Row <AddIcon style={{ fontSize: "14px" }} /></Button>
                    </div>
                </Grid>
            </Grid>
            <div>
                <FormControl className='is-handwriting-formcontrol-template'>
                    <FormLabel style={{ marginRight: '10px', color: "black" }}>Is handwriting</FormLabel>
                    <RadioGroup
                        aria-label="handwriting"
                        name="handwriting"
                        value={handwriting}
                        onChange={(event) => setHandwriting(parseInt(event.target.value))}
                        style={{ display: 'flex', flexDirection: 'row' }}
                    >
                        <FormControlLabel value={1} control={<Radio />} label="Yes" />
                        <FormControlLabel value={0} control={<Radio />} label="No" />
                    </RadioGroup>
                </FormControl>
            </div>
            <TableContainer component={Paper} style={{ marginTop: "25px" }}>
                <Table sx={{ minWidth: 650 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell className='view-edit-template-TH-cell1'>QUESTIONS</StyledTableCell>
                            <StyledTableCell className='view-edit-template-TH-cell2'>MAX MARKS</StyledTableCell>
                            <StyledTableCell className='view-edit-template-TH-cell2'>MAX WORDS</StyledTableCell>
                            <StyledTableCell className='view-edit-template-TH-cell3'>MODEL SOLUTION</StyledTableCell>
                            <StyledTableCell className='view-edit-template-TH-cell4'>SPECIAL INSTRUCION</StyledTableCell>
                            <StyledTableCell className='view-edit-template-TH-cell5'>ACTION</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(rowsPerPage > 0
                            ? viewTemplateData && viewTemplateData.length > 0 && viewTemplateData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : viewTemplateData && viewTemplateData.length > 0 && viewTemplateData
                        )?.map((row, index) => {
                            let newIndex = page * rowsPerPage + index;
                            return (
                                <StyledTableRow key={newIndex}>
                                    <StyledTableCell className='view-edit-template-TB-cell1' title={row.question}>
                                        {editEnable === newIndex ? (
                                            <TextField
                                                multiline rows={2} type="text"
                                                name="question"
                                                className='table-textfield'

                                                onChange={(e) => handleTableRowEdit(e, newIndex)}
                                                value={row.question} />
                                        ) :
                                            (row.question)}
                                    </StyledTableCell>
                                    <StyledTableCell title={row.max_marks}>
                                        {editEnable === newIndex ? (
                                            <TextField
                                                multiline rows={2} type="number"
                                                name="max_marks"
                                                className='table-textfield'

                                                onChange={(e) => handleTableRowEdit(e, newIndex)}
                                                value={row.max_marks} />
                                        ) :
                                            (row.max_marks)}
                                    </StyledTableCell>
                                    <StyledTableCell title={row.max_words}>
                                        {editEnable === newIndex ? (
                                            <TextField
                                                multiline rows={2} type="number"
                                                name="max_words"
                                                className='table-textfield'

                                                onChange={(e) => handleTableRowEdit(e, newIndex)}
                                                value={row.max_words} />
                                        ) :
                                            (row.max_words)}
                                    </StyledTableCell>
                                    <StyledTableCell className='view-edit-template-TB-cell1' title={row.model_solution}>
                                        {editEnable === newIndex ? (
                                            <TextField
                                                multiline rows={2} type="text"
                                                name="model_solution"
                                                className='table-textfield'

                                                onChange={(e) => handleTableRowEdit(e, newIndex)}
                                                value={row.model_solution} />
                                        ) :
                                            (row.model_solution)}
                                    </StyledTableCell>
                                    <StyledTableCell className='view-edit-template-TB-cell1' title={row.special_instructions}>
                                        {editEnable === newIndex ? (
                                            <TextField
                                                multiline rows={2} type="text"
                                                name="special_instructions"
                                                className='table-textfield'
                                                onChange={(e) => handleTableRowEdit(e, newIndex)}
                                                value={row.special_instructions} />
                                        ) :
                                            (row.special_instructions)}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        {editEnable === newIndex ?
                                            (
                                                <div className='view-edit-template-action-div'>
                                                    <CheckIcon className='icon-styles' onClick={(e) => handleTemplateRowUpdate(e, newIndex)} />
                                                    <CancelIcon className='icon-styles' onClick={(e) => handleTemplateRowCancel(e, newIndex)} />
                                                </div>
                                            ) :
                                            (<div className='view-edit-template-action-div'>
                                                <EditIcon className='icon-styles' onClick={(e) => handleTemplateRowEdit(e, newIndex)} />
                                                <DeleteIcon className='icon-styles' onClick={(e) => handleTemplateRowDelete(e, newIndex)} />
                                            </div>)}
                                    </StyledTableCell>
                                </StyledTableRow>
                            )
                        })}
                    </TableBody>
                </Table>
                {viewTemplateData?.length > 20 &&
                    <TablePagination
                        rowsPerPageOptions={[10, 20, 50]}
                        component="div"
                        count={viewTemplateData?.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />}
            </TableContainer>
            <div className='view-edit-btn-parent-div'>
                <Button disabled={editEnable} className="view-edit-save-btn" variant="contained" onClick={handleTemplateUpdateSave}> {loading ? <CircularProgress size={24} sx={{ color: "#fff" }} /> : "Save"}</Button>
            </div>
        </div>
    )
}

export default ViewEditTemplate